<template>
  <el-card class="margin-x">
    <div class="flex flex-between flex-v-center margin-x">
      <div></div>
      <h3 slot="header" class="center">{{ $t('沉浸式练习') }}</h3>

      <el-button @click="record()" plain round type="primary">{{ $t('练习记录') }}<i
          class="el-icon-arrow-right"></i></el-button>
    </div>

    <div>

      <el-card class="margin">
        <h4 slot="header" class="color-primary">{{ $t('功能介绍') }}:</h4>
        {{ $t('随机抽取一定量题目，高强度练习。后台自动提交自动评分。结束后统一查看评分，进行回顾，筛选出不达标的题目进行二次练习。') }}<br /><span class="st">({{ $t('练习时退出即可保存')
        }})</span>
      </el-card>

      <el-card class="margin">
        <h4 slot="header" class="color-primary">{{ $t('选择题型') }}:</h4>
        <el-radio-group @change="query()" v-model="tag">
          <el-radio border size="medium" v-for="(item, index) in tags" :key="index" :label="item.tag"></el-radio>
        </el-radio-group>
      </el-card>
      <el-card class="margin">
        <h4 slot="header" class="color-primary">{{ $t('题目类型') }}:</h4>
        <el-radio-group @change="query()" v-model="type">
          <el-radio border size="medium" v-for="(item, index) in types" :key="index" :label="item">{{ $t(item) }}</el-radio>
        </el-radio-group>
      </el-card>


      <el-card class="margin">
        <h4 slot="header" class="color-primary">{{ $t('题目数量') }}:</h4>
        <div class="margin-r">
          <el-slider v-model="num" :step="5" :max="100" :min="5" :marks="marks">
          </el-slider>
        </div>
      </el-card>

      <div class="center margin-lt">
        <el-button @click="reDo()" :disabled="!tag" round type="primary">{{
          $t('重新生成') }}<i class="el-icon-refresh-right"></i></el-button>
      </div>

      <div v-if="info">
        <el-card class="margin  margin-lt">
          <h4 slot="header" class="color-primary">{{ $store.state.lang == 'en' ? `This exercise consists of ${info.num}
            question(s)`: $t(`本次练习如下 ${info.num} 个题目`) }}:</h4>
          <div class="flex flex-wrap" style="gap: 15px;">
            <el-tag effect="dark" v-for="(no, index) in info.nos"
              :key="index">#{{ no }}</el-tag>
          </div>
        </el-card>

        <div class="center">
          <el-button @click="practise" :disabled="!tag" round type="primary" class="margin-lt">{{
            $t('开始练习') }}<i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>

export default {
  name: "immersive",
  data() {
    return {
      active: 0,
      tag: this.$route.query.tag || '',
      num: 5,
      type: this.$route.query.type || '预测',
      types: ['预测', '机经', '全部'],
      info: null
    };
  },

  computed: {
    tags: function () {
      return this.$store.state.types.filter((val) => {
        return val.is_immersive
      })
    },
    marks() {
      let data = {}
      for (let i = 5; i <= 100; i += 5) {
        data[i] = `${i}`
      }
      return data
    }
  },

  watch: {
    tags: {
      handler(val) {
        if (val && val.length > 0 && this.info === null) {
          this.tag = this.tag || val[0].tag
          this.query()
        }
      },
      immediate: true
    },
    '$route.query': {
      handler(val) {
        if (this.$route.path == '/main/immersive' && val.tag) {
          this.tag = val.tag
          this.type = val.type
          this.num = val.num
          this.query()
        }
      },
      immediate: false
    }
  },



  methods: {
    query(flag) {
      this.$http.post("/api/source/immersive", {
        tag: this.tag,
        num: this.num,
        type: this.type,
        flag: flag
      }).then(info => {
        this.info = info
        this.num = this.info.num
      })
    },


    practise() {
      this.$router.push({ path: '/main/pte', query: { tag: this.tag, immersive_id: this.info.id, source_index: this.info.source_index } })
    },
    record() {
      this.$router.push({ path: '/main/immersive/list' })
    },

    reDo() {
      if (this.info.source_index > 0) {
        this.$confirm(this.$t("您当前有一份未完成的练习") + ',' + this.$t('是否继续重新生成') + '?', this.$t("重新生成"), {
          // center: true,
          customClass: "reset",
          confirmButtonText: this.$t('确定'),
          confirmButtonClass: "confirm-btn",
          cancelButtonText: this.$t('取消'),
          type: 'warning'
        }).then(() => {
          this.query(1)
        }).catch(() => {
        })
      } else {
        this.query(1)
      }
    },
  },
};
</script>

<style scope>
.adv {
  height: 60px;
  padding: 0 20px;
  background: linear-gradient(90deg, #f57a54, #ff9f7b);
  margin-bottom: 20px;
  opacity: .9;
  border-radius: 20px;
}


#scroll-box {
  /* margin-top: 20px; */
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
</style>
